import React from 'react';
import './Style.css';

const About = () =>{
  return(
    <div className= "Content">
      <p>This is About test us Page</p>
    </div>
    
  );
};

export default About;
