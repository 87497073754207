import React from 'react';
import './Style.css';

const Home = (props) =>{
  return(
      <div className="Content">
        <p>This is Home Page.</p>
      </div>
      
  );
};

export default Home;
